#sidebarWrapper {
  display: flex;
  height: 100%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  // aside {
  //   width: 200px;
  //   min-width: 200px;

  //   @media (max-width: 1200px) {
  //     font-size: small;
  //     width: 175px;
  //     min-width: 175px;
  //   }
  // }

  #logo {
    width: 100%;
    height: 70px;
    padding: 0 25px;
    display: grid;
    margin: 5px 0 5px 0;
    color: white;

    img {
      width: 80%;
      height: 80%;
      margin: auto;
    }

    span {
      font-weight: bold;
      margin: auto;
    }
  }

  .ps-menuitem-root {
    padding: 5px 0;
    box-sizing: border-box;
    background-color: #050e22;
  }

  .ps-menu-icon {
    right: 2px;
    position: relative;
    margin-right: 0 !important;
  }

  a.ps-active {
    border-left: 5px solid rgb(235, 235, 235);

    background-color: #132242;
    border-radius: 5px;
    width: 92%;
    margin: auto;
  }

  a.ps-menu-button.ps-active {
    padding-left: 15px;
  }

  .ps-menu-button {
    height: 40px !important;
    color: white;
    font-weight: 600;

    &:hover {
      background-color: rgb(19, 34, 66);
    }
  }

  .ps-menu-label {
    font-size: 0.9rem;
  }

  #bottom {
    bottom: 0px;
    position: absolute;
    width: 100%;
    padding-bottom: 20px;
    background-color: rgb(19, 34, 66);
    color: white;

    #calendar {
      text-align: center;
    }
  }
}
