#AssignmentPage,
#SubmissionPage,
#PracticePage,
#ReviewPage {
  main {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh);
    background-color: white;
    padding: 0;

    .inner-main-wrapper {
      min-height: 100%;
      position: sticky;
      top: 0;

      .inner-max-width-wrapper {
        // background-color: white;
        border-radius: 5px;
        // padding: 15px 30px;
        // box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
        // outline: 1px solid #ececec;

        // overflow-y: scroll;
        // max-height: calc(100vh - 100px);
      }

      .top-area {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;
        padding: 15px 30px;

        h4 {
          font-size: large;
        }
      }

      .questionTitle {
        margin-bottom: 20px;
        font-size: 1.1rem;
      }

      #scroller {
        // overflow-y: scroll;
        // height: calc(100% - 50px);
        // max-height: calc(100% - 50px);
        padding: 0 30px 15px 30px;
      }

      .questionImage {
        width: 400px;
        height: 240px;
        background-image: url(../../assets/geometry-1044090_640.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 50px;
      }

      .answer-options-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px 20px;
        margin-bottom: 30px;

        .answer-option {
          outline: 1px solid #ececec;
          border-radius: 15px;
          // height: fit-content; -> this ensures that if only one answer option has an image, the one next to it doesn't have the same height
          p {
            font-size: 1rem;
          }
          .top-part {
            padding: 15px 30px;
            display: flex;
            justify-content: space-between;
          }

          hr {
            margin: 0;
            color: #ececec;
          }

          .bottom-part {
            padding: 15px 30px;
          }

          &:hover {
            cursor: pointer;
          }

          &.locked {
            background-color: #f6f6fa;

            &:hover {
              cursor: not-allowed;
              outline: 1px solid #ececec;
              &.selected-answer-option {
                outline: 1px solid #316aff;
              }
            }
          }

          // &.green {
          //   background-color: rgb(201, 234, 206);
          // }

          // &.red {
          //   background-color: rgb(234, 210, 210);
          // }

          &.selected-answer-option {
            outline: 1px solid #316aff;
          }

          &:hover {
            outline: 1px solid #316aff;
            transition: all 1s ease;
          }
        }
      }

      .explanation {
        outline: 1px solid #ececec;
        border-radius: 10px;
        padding: 20px 30px;
        margin-bottom: 20px;

        .explanation-title {
          font-size: 1.5rem;
          margin-bottom: 10px;
          font-weight: bold;
        }

        &.explanation-correct {
          // background-color: rgb(231, 247, 233);
          // outline: 1px solid green;

          .explanation-title {
            color: green;
          }
        }

        &.explanation-incorrect {
          // background-color: rgb(252, 240, 240);
          // outline: 1px solid red;
          .explanation-title {
            color: red;
          }
        }

        .explanation-line {
          .katex {
            font-size: smaller;
          }
        }

        p {
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }

    .questions-progress-tracker {
      outline: 1px solid #ececec;
      // border-radius: 5px;
      // box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
      background-color: white;
      height: calc(100vh);
      overflow-y: scroll;
      position: sticky;
      top: 0;

      .sticky-top-part {
        position: sticky;
        background-color: white;
        top: 0px;
        padding: 30px 30px 10px 30px;
        border-bottom: 1px solid #ececec;
      }

      h5 {
        font-size: 1.1rem;
        font-weight: bold;
        text-decoration: underline;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;
          font-weight: bold;

          span[class^="progress-total"] {
            float: right;
          }

          .progress-total-correct {
            color: green;
          }

          .progress-total-incorrect {
            color: red;
          }

          .progress-total-skipped {
            // color: orange;
            color: grey;
          }
        }
      }

      .past-questions-tracker {
        padding: 25px 30px 30px 30px;
        ul {
          li {
            button {
            }
            .orange_a {
              color: orange !important;
            }
          }
        }
      }
    }

    .Button {
      margin-left: 10px;
    }
  }
}
