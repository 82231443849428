$color_primary: #316aff;

#loginPage {
  height: 100vh;
  width: 100vw;


  .login-inner-wrapper {
    padding: 25px;
    border-radius: 5px;

    h1 {
      text-align: center;
      font-size: 1.8rem;
    }

    form {
      width: 350px;
      label {
        font-size: 0.9rem;
      }

      input {
        font-size: 0.9rem;
        border: none;
        outline: 1px solid rgb(232, 232, 232);
        border-radius: 5px;
        padding: 5px;
        box-shadow: 0 3px 10px rgba(180, 180, 180, 0.1);
        // border: 1px solid rgb(209, 209, 209);
      }
    }
  }

  .row {
    height: 100%;
    width: 100%;
    margin: 0;

    .loginColumn {
      background-color: white;
      display: grid;

      .loginColumnWrapper {
        margin: auto;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        form {
          label {
            margin-top: 15px;
            margin-bottom: 5px;
          }

          #forgotPasswordLink {
            display: block;
            margin: 15px 0 30px 0;
          }
        }
      }
    }

    .rightColumn {
      background-color: rgb(208, 208, 208);
      background-image: url(../../assets/loginBackground.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    }
  }
}
