footer {
  $color_primary: #316aff;
  $color_secondary: #091228;
  $color_tertiary: #262f45;
  $color_fourth: white;

  background-color: $color_secondary;
  color: $color_fourth;

  h4 {
    color: white;
    font-weight: bold;
  }

  .footer-cols-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .col-1 {
      .logo-footer {
        margin-bottom: 20px;
      }

      .social-icons-wrapper {
        display: flex;

        img {
          background-color: $color_tertiary;
          padding: 10px;
          border-radius: 20px;

          &:hover {
            background-color: $color_primary;
          }
        }
      }
    }

    .col-1,
    .col-2,
    .col-3 {
      width: 100%;
      padding: 75px 0;

      .col-inner-wrapper {
        width: 60%;
        margin: auto;

        ul {
          list-style: none;
          padding-left: 0;

          li {
            margin-bottom: 10px;

            a {
              text-decoration: none;
              color: $color_fourth;
            }
          }
        }
      }
    }

    .col-2 {
      border-left: 1px solid #fafafa2f;
      border-right: 1px solid #fafafa2f;
    }

    .col-3 {
      #backToTop {
        position: relative;
        top: 20px;
        float: right;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .footer-bottom-part {
    background-color: $color_tertiary;

    p {
      padding: 15px 0;
      text-align: center;
      margin-bottom: 0;
    }
  }
}


@media (max-width: 1000px) {
  footer {
    .footer-cols-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media (max-width: 768px) {
  footer {
    .footer-cols-wrapper {
      grid-template-columns: 1fr;
      padding: 35px 0;

      .col-1,
      .col-2,
      .col-3 {
        padding: 15px 0;

        .col-inner-wrapper {
          width: 80%;
        }
      }
    }
  }
}